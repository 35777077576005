<template>
  <div class="candlestick">
    <div class="title expand">全国碳市场(大宗交易)</div>
    <div class="echart-wrapper">
      <div class="echart-ref" ref="echart_ref"></div>
      <!-- <div class="echart-ref" ref="echart_ref_combine"></div> -->
    </div>
    <el-table ref="multipleTable" :data="tableData_Expand" style="margin-left: 228px;">
      <el-table-column
        prop="current_date"
        label="日期"
        align="left"
        width="150px"
        class-name="current_date"
        header-align="left"
      >
      </el-table-column>
      <el-table-column
        prop="amount"
        label="成交量"
        width="150px"
        align="right"
        header-align="right"
      >
      </el-table-column>
      <el-table-column
        prop="total"
        label="成交额"
        width="150px"
        align="right"
        header-align="right"
      >
      </el-table-column>
      <el-table-column
        prop="average_price"
        label="均价"
        width="150px"
        align="right"
        header-align="right"
      >
      </el-table-column>
      <el-table-column
        prop="discount_premium"
        label="折溢价"
        width="150px"
        align="right"
        header-align="right"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.discount_premium.startsWith('-')"
            style="color: #07c160"
          >
            {{ scope.row.discount_premium }}
          </span>
          <span v-else-if="/^0.0+%$/.test(scope.row.discount_premium)">
            {{ scope.row.discount_premium }}
          </span>
          <span
            v-else="scope.row.discount_premium.startsWith('+')"
            style="color: #ff2832"
          >
            {{ scope.row.discount_premium }}
          </span>
        </template>
        <!-- <span> {{}} </span> -->
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { toFixed } from "../../utils/util";
export default {
  name: "Candlestick",
  data() {
    return {
      tableData_Expand: [],
      citys: [{ name: "全国", value: "china" }],
    };
  },
  activated() {
    console.log("Candlestick, activated");
    this.instance && this.instance.dispose();
    this.initInstance();
    this.initTrendOption();
    // this.getTrendEchartsData();
    this.getTableDataExpand();
    window.addEventListener("resize", this.handleResize);
  },
  methods: {
    // 拿到基础数据
    async getTableDataExpand() {
      return new Promise((resolve) => {
        // http://192.168.23.15:8000/article/carbonsalelist
        this.api
          .postFormAPISM(
            {
              ...this.pagination,
            },
            "/article/carbonsalelist"
          )
          .then((res) => {
            console.log("getTableData", res.data);
            this.tableData_Expand = res.data.data.daily_sale_list;
            this.options.xAxis[0].data = res.data.data.daily_sale_list.map(item => item.create_time.split(' ')[0]).reverse();
            this.options.series[0].data = res.data.data.daily_sale_list.map(item => item.average_price).reverse();
            this.setTrendOption();
            // this.pagination.page_count = res.data.data.pagination.page_count;
            resolve(this.tableData_Expand[0]);
          })
          .catch((error) => {
            console.log("error", error);
          });
        // this.tableData_Expand = this.$store.state.oneTrade.daily_sale_list;
      });
    },
    startUsing() {},
    rowClick(val) {
      console.log("val", val);
    },
    initInstance() {
      let ref = this.$refs.echart_ref;
      this.ref = ref instanceof Array ? ref[0] : ref;
      this.instance = echarts.init(this.ref, null, { renderer: "svg" });
      let that = this;
      this.instance.on("showTip", function (params) {});
      this.instance.on("hideTip", function (params) {});
    },
    initTrendOption() {
      this.options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              show: false,
            },
            lineStyle: {
              type: "solid",
            },
            crossStyle: {
              type: "solid",
            },
          },
          valueFormatter: (value) => {
            return '均价：' + toFixed(value, 2) + "元";
          }
        },
        grid: [
          {
            left: 80,
            right: 35,
            bottom: 40,
            top: "10%",
            height: "65%",
          },
        ],
        xAxis: [
          {
            type: "category",
            data: [],
            boundaryGap: false,
            axisLine: { onZero: false },
            splitLine: { show: false },
          },
        ],
        yAxis: [
          {
            axisLabel: { show: true },
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: true },
            splitArea: {
              show: false,
            },
          },
        ],
        dataZoom: [
          {
            type: "inside",
            startValue: 999790,
            endValue: 999999,
          },
          {
            type: "slider",
            startValue: 999790,
            endValue: 999999,
            top: "80%",
          },
        ],
        series: [
          {
            name: "全国",
            type: "line",
            data: [],
            smooth: true,
            symbol: "circle",
            symbolSize: 10,
            showSymbol: false,
            connectNulls: false,
            itemStyle: {
              color: "red",
              shadowColor: "red",
              shadowBlur: 15,
            },
          },
        ],
      };
    },
    setTrendOption() {
      // this.options.xAxis[0].data = this.current_dates;
      // for (let i = 0; i < this.citys.length; i++) {
      //   this.options.series[i].data = this[this.citys[i].value + "_prices"];
      //   // console.log('setTrendOption', this.citys[i].value ,this.options.series[i].data);
      // }
      console.log("setTrendOption", this.options);
      this.instance.hideLoading();
      this.instance.setOption(this.options);
    },
  },
};
</script>
<style lang="less" scoped>
.candlestick {
  flex-grow: 1;
  width: 1200px;
  margin: auto;
  padding: 20px 10px 0;
  display: flex;
  flex-flow: column nowrap;

  & > .echart-wrapper {
    flex-grow: 1;
    min-height: 700px;
    max-height: 700px;
    overflow: hidden;
    // display: flex;
    // flex-flow: column nowrap;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 20px;

    & > .echart-ref {
      //   flex-grow: 1;
      //   height: 50%;
      & > div {
        &:first-child {
          height: 100%;
        }
      }
    }
  }

}
</style>
